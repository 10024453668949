import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';


import Web3 from 'web3';
import { Box, Button, Container, createStyles, createTheme, Grid, LinearProgress, makeStyles, MenuItem, Paper, Select, Theme, ThemeProvider, Typography, withStyles } from '@material-ui/core';
import detectEthereumProvider from '@metamask/detect-provider';
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { ethers } from 'ethers';
import { WalletLink } from 'walletlink';
import Fortmatic from "fortmatic";
import Torus from "@toruslabs/torus-embed";
import Portis from '@portis/web3';
import HideAppBar from './components/scrollingAppBar';
import BasicImageList from './components/basicImageList';



var abi = require('./abi.json');
const formaticKey = 'pk_live_A21C9588031CA7A0';
const portisKey = '6282a442-bc2f-4a7f-8016-be12f56e2885';


declare let window: any;
const useStyles = makeStyles({
  root: {
    //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'black',
    padding: 20,
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: '100vh'
  },
  connectionInfo: {
    
    borderRadius: 3,
    
    color: 'black',
    padding: 20,
    textAlign: 'left',
    marginBottom: 20
  },
  mallowImage: {
    width: '80%',
    margin: '0 auto',
    boxShadow: '0 0 6px 1px #222'
  }
});


const theme = createTheme({
  typography: {
    fontFamily: [
      'Chivo',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 900
    },
    h6: {
      fontWeight: 900
    }
  },
});

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 25,
      borderRadius: 5,
      border: 'solid 1px #CCC'
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar1Buffer: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
    bar2Buffer: {
      borderRadius: 5,
      backgroundColor: '#1a90ffA0',
    },
  }),
)(LinearProgress);

interface Mallow {
  id: string;
  meta: any;
  image?: string;
  byoa?: string;
  byoaVersion? : string;
}

function App() {
  const classes = useStyles();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [walletError, setWalletError] = useState<string | null>(null);
  const [accountAddress, setAccountAddress] = useState<string | null>(null);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [isMinting, setIsMinting] = useState<boolean>(false); 
  const [web3, setWeb3] = useState<any>(null);
  const [saleOpen, setSaleOpen] = useState<boolean>(false);
  const [remaining, setRemaining] = useState<number|null>(null);
  const [mintAmount, setMintAmount] = useState<number>(3);
  const [mintTransactionAddress, setMintTransactionAddress] = useState<string|null>(null);
  const [mallows, setMallows] = useState<Mallow[]>([]);
  const [findingMallows, setFindingMallows] = useState<boolean>(false);
  const [gasData, setGasData] = useState<number>(1000000000*150);
  const mallowContractAddress = '0xf20eaeae0390803b1a7c8ab4c506870b81e1048e';

  const [wProvider, setWProvider] = useState<any>(null);
  const [w3Modal, setW3Modal] = useState<any>(null);

  const [w3DataProvider, setW3DataProvider] = useState<any>(null);
 

  useEffect( () => {
    //setW3DataProvider(ethers.getDefaultProvider('https://eth-mainnet.alchemyapi.io/v2/Uo717K-DDAxlSM5gXM-zgv678k0aMZH5'));
    setInterval(async () => {
      await checkMintingDetails();
    }, 15000)
    
    checkMintingDetails();
    getGasData();
    setUpWeb3Modal();
  }, []);

  const setUpWeb3Modal = async () : Promise<any> => {
    const providerOptions = {
      walletconnect: {
        display: {
          name: "Mobile"
        },
        package: WalletConnectProvider,
        options: {
          infuraId: "6430aa46e9354b91bea44e464af71f7a" // required
        }
      },
      'custom-coinbase': {
        display: {
          logo: '/coinbase.svg', 
          name: 'Coinbase Wallet',
          description: 'Scan with WalletLink to connect',
        },
        options: {
          appName: 'app', // Your app name
          networkUrl: `https://mainnet.infura.io/v3/6430aa46e9354b91bea44e464af71f7a`,
          chainId: 1,
        },
        package: WalletLink,
        connector: async (_ : any, options : any) => {
          const { appName, networkUrl, chainId } = options
          const walletLink = new WalletLink({
            appName
          });
          const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
          await provider.enable();
          return provider;
        },
      },
      fortmatic: {
        package: Fortmatic, // required
        options: {
          key: formaticKey // required
        }
      },
      torus: {
        package: Torus,
      },
      portis: {
        package: Portis, // required
        options: {
          id: portisKey // required
        }
      }
    };
    
    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      disableInjectedProvider: false,
      providerOptions // required
    });
    setW3Modal(web3Modal);
    return web3Modal;
  };

  const connectWallet = async () => {
    try {
      let w3 = w3Modal
      if (!w3) {
        w3 = await setUpWeb3Modal();
      }

      let provider = await establishProvider();
      const accounts = await provider.request({method: 'eth_accounts'});
      if (accounts.length === 0) {
        setWalletError(`Error: you have no accounts connected`);
      } else {
        setIsConnected(true);
        setAccountAddress(accounts[0]);
      }

      
        findMallows();
      
      
    } catch (error) {
      alert('Could not connect wallet');
    }

  };

  const disconnectWallet = async () => {
    await w3Modal.clearCachedProvider();
    setWProvider(null);
    setAccountAddress(null);
    setIsConnected(false);
  };

  const establishProvider = async () : Promise<any> => {
    try{
      if (wProvider) {
        return wProvider;
      }
      const providerOptions = {
        
        walletconnect: {
          display: {
            name: "Mobile"
          },
          package: WalletConnectProvider,
          options: {
            infuraId: "6430aa46e9354b91bea44e464af71f7a" // required
          }
        }
      };
      /*
      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        disableInjectedProvider: false,
        providerOptions // required
      });
      setW3Modal(web3Modal);
      */
      
      const provider = await w3Modal.connect();
      setWProvider(provider);
      
      const web3 = new Web3(provider);
      console.log(`Got Web3`, web3);
      return provider;
    } catch (error) {
      return null;
    }
  };

  const mint = async () => {
    try{
      const providerOptions = {
        
        walletconnect: {
          display: {
            name: "Mobile"
          },
          package: WalletConnectProvider,
          options: {
            infuraId: "6430aa46e9354b91bea44e464af71f7a" // required
          }
        }
      };
      
      const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        disableInjectedProvider: false,
        providerOptions // required
      });
      
      const provider = await web3Modal.connect();
      setWProvider(provider);
      
      const web3 = new Web3(provider);
      console.log(`Got Web3`, web3);

      //let gd = await getGasData();

      const accounts = await provider.request({method: 'eth_accounts'});
      if (accounts.length === 0) {
        setWalletError(`Error: you have no accounts connected`);
      } else {
        setIsConnected(true);
        setAccountAddress(accounts[0]);
      }

      let amount = mintAmount;
      let abiItem = abi['abi'];
      const paymentAmount = web3.utils.toHex(amount * parseInt(web3.utils.toWei('0.04', 'ether')));
      let contract = new web3.eth.Contract(abiItem, mallowContractAddress);
      // @ts-ignore
      let callData = contract.methods.mint(amount).encodeABI();
  
      // estimate the gas price
      let gp = gasData;
  
  
      const mintParams = [
        {
          from: accounts[0],
          to: mallowContractAddress,
          gas: web3.utils.toHex(Math.min(160000*amount,3000000)), 
          //gasPrice: web3.utils.toHex(gp), 
          value: paymentAmount, 
          data: callData,
        },
      ];
  
      
        // @ts-ignore
        let res = await provider.request({
          method: 'eth_sendTransaction',
          params: mintParams,
        })
        setMintTransactionAddress(res);
    } catch (error) {
      alert('Minting Transaction Stopped');
    }
  }



  const getGasData = async () => {
    try {
      let req = await fetch("https://data-api.defipulse.com/api/v1/egs/api/ethgasAPI.json?api-key=ccb859beaf88b6274485b72694eab01840554d4f469972e19952881d30ec");

      let data = await req.json();
      let fastGasCost = (data['fast'] || 1250)/10;
      
      console.log(data);
      setGasData(1000000000 * fastGasCost);
      console.log(1000000000 * fastGasCost)
    } catch (error) {
      setGasData(1000000000 * 125);
      console.log(`Gas Error: `, error);
      return;
    }
  }

  const noDefault = (attr : string) : string => {
    return attr.replace("- Default", "");
  }
  

  const ensureWalletConnection = async () => {
    const provider = await detectEthereumProvider();
    if (provider == null) {
      console.log('returning');
      return;
    }

    try {
      // @ts-expect-error
      const accounts = await provider.request({method: 'eth_requestAccounts'});
      if (accounts.length === 0) {
        setWalletError(`Error: you have no accounts connected`);
      } else {
        setIsConnected(true);
        setAccountAddress(accounts[0]);
      }
    } catch(error) {
      setWalletError(`Error fetching account address details from wallet.`)
    }
  };

  const sleep = (ms : any) =>
    new Promise(resolve => setTimeout(resolve, ms));

  const findMallows = async () => {
    
    setFindingMallows(true);
    
    try {
      let provider = await establishProvider();
      /*
      await ensureWalletConnection();
      const provider = await detectEthereumProvider();
      */
      if (provider == null) {
        setFindingMallows(false);
        return;
      };
      const accounts = await provider.request({method: 'eth_accounts'});
      setAccountAddress(accounts[0])
      setIsConnected(true);
      let abiItem = abi['abi'];
      let w3 = new Web3(new Web3.providers.HttpProvider('https://eth-mainnet.alchemyapi.io/v2/-JzFAkkwhWgF7R4bqIxUvWKDvjP36bCV'));

      let contract = new w3.eth.Contract(abiItem, mallowContractAddress);
      let myMallows = await contract.methods.walletOfOwner(accounts[0]).call();
      console.log(myMallows);
      let ms : Mallow[] = [];
      for(let i = 0; i < myMallows.length; i += 1) {
        console.log("Searching...", i)
        let id = myMallows[i];
        
        let uri = await contract.methods.tokenURI(id).call();
        uri = uri.split('ipfs://')[1];
        
        try {
          // Resolve with Cloudflare IPFS
          let metadata = await fetch(`https://cloudflare-ipfs.com/ipfs/${uri}`);
          const jsonData = await metadata.json();
          

          let normalizedData : any = {};
          jsonData.attributes.forEach( (attr :any) => {
            normalizedData[attr['trait_type']] = attr['value'];
          })
          console.log(jsonData);
          
          ms.push({
            id: id,
            meta: normalizedData,
            image: `https://cloudflare-ipfs.com/ipfs/${(jsonData.image || "").split('ipfs://')[1]}`,
            byoa: `https://cloudflare-ipfs.com/ipfs/${jsonData.byoa.split('ipfs://')[1]}`,
            byoaVersion: jsonData.byoaVersion
          });

          setMallows(ms);
        } catch (error) {
          console.log('Skipping ', myMallows[i], error)
        }

        await sleep(2000);
        
      }
      
        
      
      setMallows(ms);
      setFindingMallows(false);
    } catch (error) {
      console.log(`Could not find mallows.`, error);
      setFindingMallows(false);
    }
    setFindingMallows(false);

  }

  const checkMintingDetails = async () : Promise<boolean> => {
    return new Promise<boolean>( async (resolve, reject) => {
        try {
          let abiItem = abi['abi'];
          // @ts-ignore
          let w3 = new Web3(new Web3.providers.HttpProvider('https://eth-mainnet.alchemyapi.io/v2/-JzFAkkwhWgF7R4bqIxUvWKDvjP36bCV'));
          let contract = new w3.eth.Contract(abiItem, mallowContractAddress);
          // @ts-ignore
          let cd = await contract.methods.isSaleOpen().call();
          setSaleOpen(cd);
          let ts = await contract.methods.totalSupply().call();
          setRemaining(10000-ts);
          resolve(true);
        }
        catch (error) {
        
          console.log(`Fetching minting details is failing: ${error}`);
          reject(error);
        }
    })
    
  };

  const connectWithMetamask = async () => {
    setWalletError(null);
    setIsConnecting(true);

    const provider = await detectEthereumProvider();


    if (provider == null) {
      setWalletError('Metamask is not enabled');
      setIsConnecting(false);
      return;
    }

    // @ts-ignore
    setWeb3(new Web3(provider));


    try {
      // @ts-expect-error
      const accounts = await provider.request({method: 'eth_requestAccounts'});
      if (accounts.length === 0) {
        setWalletError(`Error: you have no accounts connected`);
      } else {
        setIsConnected(true);
        setAccountAddress(accounts[0]);
      }
    } catch(error) {
      setWalletError(`Error fetching account address details from wallet.`)
    }
    


    setIsConnecting(false);

  };



  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Box className={classes.root}>
          <HideAppBar 
            address={accountAddress}
            onConnect={connectWallet}
            onDisconnect={disconnectWallet}
          />
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box>
                  <Typography variant="h1" style={{fontSize: 36}}>welcome fren</Typography>
                  <Typography variant="h5" component="h2">it's time to enter the mallow-verse</Typography>
                  
                </Box>
                <Box style={{marginTop: 20}}>
                  <Typography variant="h1" style={{fontSize: 36}}>{`${(10000 - (remaining || 0)).toLocaleString("en-US")}`}</Typography>
                  <Typography variant="body1">mallows minted</Typography>
                </Box>

                {!isConnected && (
                  <Box style={{marginTop: 20}}>
                    <Button variant="outlined" onClick={connectWallet} style={{
                      width: '60%',
                      textTransform: 'none'
                    }} >Connect Wallet to Mint</Button>
                  </Box>
                )}

                {isConnected && (
                  <Box style={{marginTop: 50}}>
                    
                    <Select
                      value={mintAmount}
                      onChange={(e :any) => {setMintAmount(e.target.value)}}
                      style={{marginRight: 20}}
                    >
                        { Array.from(new Array(20).keys()).map( (_, i) => (
                          <MenuItem value={i+1}>{i+1}</MenuItem>
                        ))}
                    </Select>
                    <Button variant="outlined" onClick={mint} disabled={!isConnected} 
                      style={{
                        textTransform: 'none',
                        width: '40%',
                        backgroundColor: 'rgb(81,235,43)',
                        fontWeight: 'bold'
                      }}
                    >Mint {mintAmount} Mallows</Button>
                    
                    <Box style={{marginTop: 10}}>
                      <Typography variant="h1" style={{fontSize: 36}}>ready to mint yours? let's go!</Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption">0.04Ξ / mallow</Typography>
                    </Box>
                    <Box>
                     <Typography variant="caption"><a target="_blank" href="https://etherscan.io/address/0xf20eaeae0390803b1a7c8ab4c506870b81e1048e#code" style={{textDecoration: 'undefline', color: 'black'}}> smart contract</a></Typography>
                    </Box>
                    <Box>
                      {mintTransactionAddress && (
                        <Container className={classes.connectionInfo}>
                          <Typography>Last mint transaction id: {mintTransactionAddress}. <br/>View on <a target="_blank" href={`https://etherscan.io/tx/${mintTransactionAddress}`}>etherscan</a>.</Typography>
                        </Container>
                      )}
                    </Box>
                  </Box>
                )}

                
              </Grid>
              <Grid item xs={12} sm={6}>
                <BasicImageList itemData={
                  [
                    {
                      img: "https://uploads-ssl.webflow.com/610b3b6949ea71d39a7ab352/610b3e398e603328b9d19d82_mellow-2797-p-500.png",
                      title: "A Perfect Mallow",
                      author: "@MallowsXYZ",
                      cols: 2
                    },
                    {
                      img: "https://uploads-ssl.webflow.com/610b3b6949ea71d39a7ab352/61106b320ac41d777abc7c37_mallow-preview-3-p-500.png",
                      title: "Minty mallow!",
                      author: "@MallowsXYZ",
                      cols: 1
                    },
                    {
                      img: "https://uploads-ssl.webflow.com/610b3b6949ea71d39a7ab352/621016ec227d5d174f96560b_Screen%20Shot%202022-02-18%20at%204.59.42%20PM-p-500.png",
                      title: "Mallow with their cyber punk byoa goggles",
                      author: "@MallowsXYZ",
                      cols: 3
                    },
                    {
                      img: "https://uploads-ssl.webflow.com/610b3b6949ea71d39a7ab352/61106b32660e8b66be21c27d_mallow-preview-4-p-500.png",
                      title: "Mallow with a Beanie",
                      author: "@MallowsXYZ",
                      cols: 1
                    },
                    {
                      img: "https://uploads-ssl.webflow.com/610b3b6949ea71d39a7ab352/61106b325011bf3a0a293a28_mallow-preview-5-p-500.png",
                      title: "Mallow with Headphones and Glasses and Going to Mallow-Con",
                      author: "@MallowsXYZ",
                      cols: 2
                    }
                  ]
                } />
              </Grid>
            </Grid>
          </Container>

          <Box style={{
            backgroundColor: 'black',
            color: 'white',
            marginTop: 20,
            paddingTop: 35,
            paddingBottom: 35
          }}>
            <Container>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h5">
                    Mallows are the first byoa NFT, completely unique generative art with rare traits, and gets you access to the private mallow-verse discord community.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h5">
                      With byoa, you control your own user experience. The tech behind mallows powers the byoa ecosystem - now on StarkNet!
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
          </Box>
          <Box style={{
            backgroundColor: 'rgb(81,235,43)',
            color: 'black',
            marginBottom: 20,
            paddingTop: 35,
            paddingBottom: 35
          }}>
            <Container>
              <Typography variant="h1" style={{fontSize: 26}}>
                Once you've got your mallows, let's help you look at cool your nerds...
              </Typography>
              <Box style={{marginTop: 20}}>
                <Button variant="outlined" onClick={findMallows} disabled={findingMallows || !isConnected}
                  style={{
                    width: '50%',
                    backgroundColor: 'black',//'rgb(81,235,43)',
                    color: 'rgb(81,235,43)',
                    textTransform: 'none'
                  }}
                >{findingMallows ? '...searching for Mallows...' : 'Find my Mallows'}</Button>
                {mallows.length ==0 && (
                  <>
                    <br/>
                    <Typography variant="caption">if all of your mallows aren't showing, click 'find my mallows'</Typography>
                  </>
                )}
                <p>You have {mallows.length} mallows found.</p>
              </Box>
              {mallows.length > 0 && (
                <Box>
                  <hr/>
                  <Grid container spacing={3}>
                    {mallows.map( (mallow : Mallow, i) => (
                      <Grid key={`m-${i}`} item xs={12} sm={6}>
                        <Paper className={classes.connectionInfo}>
                          <Typography>#{mallow.id}</Typography>
                          <img className={classes.mallowImage} src={mallow.image} />
                          <Box>
                            <hr/>
                            <Button variant="contained" href={`https://showcase.mallows.xyz?tokenId=${mallow.id}`}>Launch Algorithm</Button>
                            <hr/>
                            <Typography variant="body1" key={"language"}>Language: <b>{mallow.meta['language']}</b></Typography>
                            <Typography variant="body1" key={"editor"}>Editor: <b>{mallow.meta['editor']}</b></Typography>
                            <Typography variant="body1" key={"mallowcon"}>Mallow Con: <b>{mallow.meta['mallow_con']}</b></Typography>
                            <Typography variant="body1" key={"accessory"}>Accessory: <b>{mallow.meta['accessory']}</b></Typography>
                            <Typography variant="body1" key={"glasses"}>Glasses: <b>{mallow.meta['glasses']}</b></Typography>
                            <Typography variant="body1" key={"eyes"}>Eyes: <b>{mallow.meta['eyes']}</b></Typography>
                            <Typography variant="body1" key={"subgenus"}>Sub Genus: <b>{mallow.meta['sub_genus']}</b></Typography>
                            <Typography variant="body1" key={"vibe"}>Vibe: <b>{mallow.meta['vibe']}</b></Typography>
                            <Typography variant="body1" key={"algorithm"}>Algorithm: <b>{mallow.meta['algo']}</b></Typography>
                            <Box style={{wordWrap: 'break-word'}}>
                              <Typography variant="caption">byoa.{mallow.byoaVersion}</Typography>
                              <br/>
                              <Typography variant="caption">byoa://{mallow.byoa}</Typography>
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    ))}
                    
                  </Grid>
                </Box>
              )}
            </Container>
          </Box>
          
          


          <Box>
            <Typography>Back to <a href="https://mallows.xyz" style={{textDecoration: 'none', color: 'black', fontWeight: 'bold'}}> mallows.xyz</a></Typography>
          </Box>

        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
