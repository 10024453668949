import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import Slide from '@material-ui/core/Slide';
import { Button, Link, makeStyles } from '@material-ui/core';

function HideOnScroll(props : any ) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const useStyles = makeStyles({
   title: {
       flexGrow: 1,
       textAlign: 'left'
   },
   baseBar: {
        //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        backgroundColor: 'black',
        color: 'rgb(81,235,43);'
   }
   
  });

  


export default function HideAppBar(props : any) {
    const classes = useStyles();
    const handleConnection = () => {
        if (props.address === null) {
            // connect
            if (props.onConnect) {
                props.onConnect();
            }
        } else { 
            // disconnect 
            if(props.onDisconnect) {
                props.onDisconnect();
            }
        }
    }
    return (
        <React.Fragment>
        <CssBaseline />
        <HideOnScroll {...props}>
            <AppBar className={classes.baseBar}>
                <Toolbar>
                    <Typography variant="h6" component="div" className={classes.title}>
                        <Link target="_blank" href="https://mallows.xyz" style={{color: 'rgb(81,235,43)'}}>mallows.xyz</Link></Typography>

                        <Typography variant="h6" style={{marginRight: 30}} >
                            <Link href="/" style={{
                                fontWeight: 'bold',
                                color: 'white',
                                textDecoration: 'none'
                            }}>Mint</Link>
                        </Typography>

                        <Typography variant="h6" style={{marginRight: 30}} >
                            <Link href="https://mallows.xyz" target="_blank" style={{
                                fontWeight: 'bold',
                                color: 'white',
                                textDecoration: 'none'
                            }}>Learn More</Link>
                        </Typography>

                        <Typography variant="h6" style={{marginRight: 30}} >
                            <Link href="https://byoa.org" target="_blank" style={{
                                fontWeight: 'bold',
                                color: 'white',
                                textDecoration: 'none'
                            }}>About byoa</Link>
                        </Typography>

                        <Typography variant="h6" style={{marginRight: 30}} >
                            <Link href="https://twitter.com/mallowsxyz" target="_blank" style={{
                                fontWeight: 'bold',
                                color: 'white',
                                textDecoration: 'none'
                            }}>@MallowsXYZ</Link>
                        </Typography>
                        <Button onClick={handleConnection} color="inherit" variant="outlined">{props.address ? `${props.address.substring(0,10)}... Disconnect` : 'Connect Wallet'}</Button>
                </Toolbar>
            </AppBar>
        </HideOnScroll>
        <Toolbar />
        </React.Fragment>
    );
}
